export const LOGIN_MODAL_OPEN = 'LOGIN_MODAL_OPEN'; 
export const LOGIN_MODAL_CLOSED = 'LOGIN_MODAL_CLOSED';
export const LOGIN_REDIRECT = "LOGIN_REDIRECT";
export const REGISTER_MODAL_TYPE = "REGISTER_MODAL_TYPE";
export const REGISTER_MODAL_OPEN = 'REGISTER_MODAL_OPEN'; 
export const REGISTER_MODAL_CLOSED = 'REGISTER_MODAL_CLOSED';
export const REGISTER_COMPLETED = 'REGISTER_COMPLETED'; 
export const RESET_REGISTER_COMPLETED = 'RESET_REGISTER_COMPLETED';
export const USER_LOGGED_IN = 'USER_LOGGED_IN'; 
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT'; 
export const LOGIN_ERRORED = 'LOGIN_ERRORED'; 
export const SIGNUP_ERRORED = 'SIGNUP_ERRORED'; 
export const CLEARED_LOGIN_ERROR = 'CLEARED_LOGIN_ERROR';
export const LOADING_USER = 'LOADING_USER'; 
export const USER_PROFILE_UPDATED= 'USER_PROFILE_UPDATED';
export const NEW_VACANCY_SAVED = 'NEW_VACANCY_SAVED'; 
export const NEW_VACANCY_DATA = 'NEW_VACANCY_DATA'; 
export const NEW_VACANCY_PUBLISHED = 'NEW_VACANCY_PUBLISHED'; 
export const NEW_VACANCY_SAVE_ERROR = 'NEW_VACANCY_SAVE_ERROR';
export const SAVE_IN_PROGRESS = 'SAVE_IN_PROGRESS'; 
export const CLEAR_POST_JOB_STATE = 'CLEAR_POST_JOB_STATE'; 
export const FETCHED_POSTS = 'FETCHED_POSTS'; 
export const FETCHED_USER_PROFILE = 'FETCHED_USER_PROFILE'; 
export const FETCHING_USER_PROFILE_FAILED = 'FETCHING_USER_PROFILE_FAILED';
export const LOADING_PROFILE = 'LOADING_PROFILE'; 
export const FETCHED_CV_META= 'FETCHED_CV_META';
export const LOADING_CV_META = 'LOADING_CV_META';
export const FAILED_FETCHING_CV_META = 'ERROR_FETCHING_CV_META'; 
export const SAVE_CV_IN_PROGRESS = 'SAVE_CV_IN_PROGRESS'; 
export const SAVED_CV = 'SAVED_CV'; 
export const FAILED_SAVING_CV = 'FAILED_SAVING_CV'; 
export const RESET_CV = 'RESET_CV';
export const JOB_POST_DELETED = "JOB_POST_DELETED";
export const FETCHED_JOBS = 'FETCHED_JOBS'; 
export const LOADING_VACANCIES = 'LOADING_VACANCIES'; 
export const ERROR_LOADING_VACANCIES = 'ERROR_LOADING_VACANCIES'; 
export const IS_RECRUITER_SET = 'IS_RECRUITER_SET'; 
export const FETCHED_MY_VACANCIES = 'FETCHED_MY_VACANCIES';
export const LOADING_MY_VACANCIES = 'LOADING_MY_VACANCIES';
export const ERROR_FETCHING_MY_VACANCIES = 'ERROR_FETCHIN_VACANCIES'; 
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS"; 
export const PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED';
export const LOADING_PASSWORD_RESET = 'LOADING_PASSWORD_RESET'; 
export const CLEAR_PASSWORD_RESET_INPUTS = 'CLEAR_PASSWORD_RESET_INPUTS';
export const CLEAR_PASS_RESET = 'CLEAR_PASS_RESET'; 
export const USER_EMAIL_UPDATED = 'USER_EMAIL_UPDATED'; 
export const USER_EMAIL_UPDATE_FAILED = 'USER_EMAIL_UPDATE_FAILED'; 
export const USER_PHONE_UPDATED = 'USER_PHONE_UPDATED'; 
export const USER_PHONE_UPDATE_FAILED = 'USER_PHONE_UPDATE_FAILED'; 
export const USER_UPDATE_IN_PROGRESS = 'USER_UPDATE_IN_PROGRESS'; 
export const USER_WEBSITE_UPDATED = 'USER_WEBSITE_UPDATED'; 
export const USER_WEBSITE_UPDATE_FAILED = 'USER_WEBSITE_UPDATE_FAILED'; 
export const USER_DISPLAYNAME_UPDATED_IN_PROGRESS = 'USER_DISPLAYNAME_UPDATE_IN_PROGRESS'; 
export const USER_DISPLAYNAME_UPDATED = 'USER_DISPLAYNAME_UPDATED'; 
export const USER_DISPLAYNAME_UPDATE_FAILED = 'USER_DISPLAYNAME_UPDATE_FAILED'; 
export const CLEAR_RECRUITER_UPDATE = 'CLEAR_RECRUITER_UPDATE'; 
export const UPDATE_RECRUITER_IMAGE = 'UPDATE_RECRUITER_IMAGE';
export const UPDATE_RECRUITER_IMAGE_IN_PROGRESS = 'UPDATE_RECRUITER_IMAGE_IN_PROGRESS';
export const UPDATE_RECRUITER_IMAGE_FAILED = 'UPDATE_RECRUITER_IMAGE_FAILED'; 
export const APPLICATION_IN_PROGRESS = "APPLICATION_IN_PROGRESS"; 
export const APPLICATION_SUCCESS = 'APPLICATION_SUCCESS'; 
export const APPLICATION_FAILED = 'APPLICATION_FAILED';
export const CLEAR_APPLICATION = 'CLEAR_APPLICATION';
export const FETCHED_APPLICANTS = 'FETCHED_APPLICANTS'; 
export const LOADING_APPLICANTS = 'LOADING_APPLICANTS'; 
export const FAILED_FETCHING_APPLICANTS = 'FAILED_FETCHING_APPLICANTS'; 
export const CLEAR_APPLICANTS = 'CLEAR_APPLICANTS'; 
export const FETCHED_MY_APPLICATIONS = 'FETCH_MY_APPLICATIONS'; 
export const LOADING_MY_APPLICATIONS = 'LOADING_MY_APPLICATIONS'; 
export const FAILED_FETCHING_MY_APPLICATIONS = 'FAILED_FETCHING_MY_APPLICATIONS';
export const STORED_USER_MEETING_NAME = 'STORED_USER_MEETING_NAME'; 
export const SAVE_SCHEDULE_IN_PROGRESS = 'SAVE_SCHEDULE_IN_PROGRESS'; 
export const SAVED_SCHEDULE = 'SAVED_SCHEDULE'; 
export const FAILED_SAVING_SCHEDULE = 'ERROR_SAVING_SCHEDULE'; 
export const RESET_SCHEDULE = 'RESET_CLEAR_SCHEDULE';
export const FETCHED_MY_MEETINGS = 'FETCH_MY_MEETINGS'; 
export const FETCH_MY_MEETINGS_IN_PROGRESS = 'FETCH_MY_MEETINGS_IN_PRORESS'
export const FAILED_FETCHING_MY_MEETINGS = 'FAILED_FETCHING_MY_MEETINGS'; 
export const APPLICANT_STATUS_UPDATED = 'APPLICANT_STATUS_UPDATED';
export const FAILED_UPDATING_APPLICANT_STATUS = 'FAILED_UPDATING_APPLICANT_STATUS'; 
export const APPLICANT_STATUS_UPDATE_IN_PROGRESS = 'APPLICANT_STATUS_UPDATE_IN_PROGRESS';
export const RESET_APPLICANT_STATUS = 'RESET_APPLICANT_STATUS';


export const PARTICIPANTS_LIST_CHANGE = "PARTICIPANTS_LIST_CHANGE";
export const MY_VOOST_ROOM_USER = "MY_VOOST_ROOM_USER";
export const SET_MEETING_CHAT_ID = "SET_MEETING_CHAT_ID";



export const FETCHED_CURRENT_APPLICANT = 'FETCHED_CURRENT_APPLICANT';
export const LOADING_CURRENT_APPLICANT = 'LOADING_CURRENT_APPLICANT';
export const FAILED_CURRENT_APPLICANT = 'FAILED_CURRENT_APPLICANT';



export const SAVING_QUESTIONS = 'SAVING_QUESTIONS';
export const SAVED_QUESTIONS = 'SAVED_QUESTIONS';
export const SAVING_QUESTIONS_ERROR = 'SAVING_QUESTIONS_ERROR';



export const LOADING_INTERVIEW_RESPONSE = 'LOADING_INTERVIEW_RESPONSE';
export const RESPONDED_INTERVIEW = 'RESPONDED_INTERVIEW'; 


export const FETCHED_EDITABLE_JOB = 'FETCHED_EDITABLE_JOB'; 
export const LOADING_EDITABLE_JOB = 'LOADING_EDITABLE_JOB'; 
export const FAILED_EDITABLE_JOB = 'FAILED_EDITABLE_JOB'; 






/**
 * CREATE CONTRACT  
*/ 
export const CREATED_CONTRACT = 'CREATED_CONTRACT';
export const LOADING_CONTRACT = 'LOADING_CONTRACT';
export const FAILED_CONTRACT = 'FAILED_CONTRACT';

/**
 * CREATE OFFER  
*/ 
export const CREATED_OFFER = 'CREATED_OFFER';
export const LOADING_OFFER = 'LOADING_OFFER';
export const FAILED_OFFER = 'FAILED_OFFER';


/**
 * SENDING OFFER  
*/  
export const SENDING_OFFER = 'SENDING_OFFER';
export const SENT_OFFER = 'SENT_OFFER';
export const SEND_OFFER_FAILED = 'SEND_OFFER_FAILED';


/**
 * SENDING CONTRACT  
*/  
export const SENDING_CONTRACT = 'SENDING_CONTRACT';
export const SENT_CONTRACT = 'SENT_CONTRACT';
export const SEND_CONTRACT_FAILED = 'SEND_CONTRACT_FAILED';


/**
 * SENDING EMAIL  
*/  
export const SENDING_EMAIL = 'SENDING_EMAIL';
export const SENT_EMAIL = 'SENT_EMAIL';
export const EMAIL_FAILED = 'EMAIL_FAILED';



export const CV_CREATED = 'CV_CREATED';
export const LOADING_CV_CREATE = 'LOADING_CV_CREATE';
export const FAILED_CREATE_CV = 'FAILED_CREATE_CV';
export const CV_EMAILED = 'CV_EMAILED';
export const RESET_CV_ACTION = 'RESET_CV_ACTION';





/** NOTIFICATIONS */
export const HAS_NOTIFICATIONS = 'HAS_NOTIFICATIONS';


export const FETCHED_NOTIFICATIONS =    'FETCHED_NOTIFICATIONS';
export const LOADING_NOTIFICATIONS =    'LOADING_NOTIFICATIONS';
export const FAILED_NOTIFICATIONS =     'FAILED_NOTIFICATIONS';


export const FETCHED_JOBOFFER =    'FETCHED_JOBOFFER';
export const FAILED_JOBOFFER =    'FAILED_JOBOFFER';
export const LOADING_JOBOFFER =     'LOADING_JOBOFFER';
export const CLEAR_JOBOFFER =     'CLEAR_JOBOFFER';


export const CLEAR_CONTRACT =     'CLEAR_CONTRACT';
export const FETCHED_CONTRACT =   'FETCHED_CONTRACT';
export const FAILED_FETCHED_CONTRACT =    'FAILED_CONTRACT';
export const LOADING_FETCHED_CONTRACT =   'LOADING_CONTRACT';


export const SET_MESSAGES =   'SET_MESSAGES';


export const CLEAR_INTERVIEW =     'CLEAR_INTERVIEW';
export const FETCHED_INTERVIEW =   'FETCHED_INTERVIEW';
export const FAILED_FETCHED_INTERVIEW =    'FAILED_FETCHED_INTERVIEW';
export const LOADING_FETCHED_INTERVIEW =   'LOADING_INTERVIEW';
export const ACCEPTED_INTERVIEW = 'ACCEPTED_INTERVIEW';
export const DECLINED_INTERVIEW = 'DECLINED_INTERVIEW';



/**
 * PROFILE BUILDER  
*/ 
 
export const PROFILE_BUILDER_VIDEO_SOURCE = "PROFILE_BUILDER_VIDEO_SOURCE";
export const PROFILE_BUILDER_VIDEO_MODAL= 'PROFILE_BUILDER_VIDEO_MODAL';
export const PROFILE_BUILDER_SELECTION= 'PROFILE_BUILDER_SELECTION';
export const LOCAL_USER_PROFILE= 'LOCAL_USER_PROFILE';
export const PROFILE_BUILDER_STATE= 'PROFILE_BUILDER_STATE';

// WORK HISTORY
export const PROFILE_BUILDER_WORK_HISTORY = 'PROFILE_BUILDER_WORK_HISTORY';
export const WORK_HISTORY_ITEM = 'WORK_HISTORY_ITEM';
export const ALL_WORK_HISTORY_ITEMS = 'ALL_WORK_HISTORY_ITEMS';
// EDUCATION HISTORY
export const PROFILE_BUILDER_EDUCATION_HISTORY = 'PROFILE_BUILDER_EDUCATION_HISTORY';
export const ALL_EDUCATION_HISTORY_ITEMS = 'ALL_EDUCATION_HISTORY_ITEMS';
export const EDUCATION_HISTORY_ITEM = 'EDUCATION_HISTORY_ITEM';

export const SKILLS_LIST = 'SKILLS_LIST';
export const PROFILE_SUMMARY = 'PROFILE_SUMMARY';
export const CV_HIGHLIGHT = 'CV_HIGHLIGHT';
export const CV_HIGHLIGHT_BUILDER = 'CV_HIGHLIGHT_BUILDER';
export const CV_MODAL = 'CV_MODAL'; 
export const IS_REVIEWING = 'IS_REVIEWING';
export const IS_OPEN = 'IS_OPEN';
export const FEEDBACK_CONTENT = 'FEEDBACK_CONTENT';
export const CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL';
export const UPDATE_FEEDBACK_ID = 'UPDATE_FEEDBACK_ID';

export const PREP_FEEDBACK_RESPONSE = 'PREP_FEEDBACK_RESPONSE';
export const SENDING_FEEDBACK_RESPONSE = 'SENDING_FEEDBACK_RESPONSE';
export const FEEDBACK_RESPONSE_CONTENT = 'FEEDBACK_RESPONSE_CONTENT';
export const SENT_FEEDBACK_RESPONSE = 'SENT_FEEDBACK_RESPONSE';


export const GETTING_FEEDBACK_RESPONSE = 'GETTING_FEEDBACK_RESPONSE';
export const FETCHED_FEEDBACK_RESPONSE = 'FETCHED_FEEDBACK_RESPONSE';
export const ERROR_FEEDBACK_RESPONSE = 'ERROR_FEEDBACK_RESPONSE';


