import React, { useEffect } from 'react'; 
import CompleteDocument from '../components/create-document/complete-document'; 
import {useHistory} from 'react-router-dom';   

export default function CompleteDocumentPage() {
    
    return (
        <> 
            <CompleteDocument /> 
        </>
    )

}