// import React from 'react';

export const categoryData = [ "Marketing & Advertising",
     "Arts", 
    "Education",
    "Development",
    "Manufacturing",
    "Hospitality",
    "Production",
    "Sales"
];