import {
  getYear,
  getFacebook,
  getLinkedIn,
  getTwitter,
  getAccountUrl,
  getJobsURL,
  getLogoURL
} from "./templateHelper"; 

export const welcomeSeekerEmail = function (user) {
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
  
  <head>
	  <!--[if gte mso 9]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]-->
	  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
	  <meta name="viewport" content="width=device-width">
	  <!--[if !mso]><!-->
	  <meta http-equiv="X-UA-Compatible" content="IE=edge">
	  <!--<![endif]-->
	  <title></title>
	  <!--[if !mso]><!-->
	  <!--<![endif]-->
	  <style type="text/css">
		  body {
			  margin: 0;
			  padding: 0;
		  }
  
		  table,
		  td,
		  tr {
			  vertical-align: top;
			  border-collapse: collapse;
		  }
  
		  * {
			  line-height: inherit;
		  }
  
		  a[x-apple-data-detectors=true] {
			  color: inherit !important;
			  text-decoration: none !important;
		  }
	  </style>
	  <style type="text/css" id="media-query">
		  @media (max-width: 520px) {
  
			  .block-grid,
			  .col {
				  min-width: 320px !important;
				  max-width: 100% !important;
				  display: block !important;
			  }
  
			  .block-grid {
				  width: 100% !important;
			  }
  
			  .col {
				  width: 100% !important;
			  }
  
			  .col_cont {
				  margin: 0 auto;
			  }
  
			  img.fullwidth,
			  img.fullwidthOnMobile {
				  max-width: 100% !important;
			  }
  
			  .no-stack .col {
				  min-width: 0 !important;
				  display: table-cell !important;
			  }
  
			  .no-stack.two-up .col {
				  width: 50% !important;
			  }
  
			  .no-stack .col.num2 {
				  width: 16.6% !important;
			  }
  
			  .no-stack .col.num3 {
				  width: 25% !important;
			  }
  
			  .no-stack .col.num4 {
				  width: 33% !important;
			  }
  
			  .no-stack .col.num5 {
				  width: 41.6% !important;
			  }
  
			  .no-stack .col.num6 {
				  width: 50% !important;
			  }
  
			  .no-stack .col.num7 {
				  width: 58.3% !important;
			  }
  
			  .no-stack .col.num8 {
				  width: 66.6% !important;
			  }
  
			  .no-stack .col.num9 {
				  width: 75% !important;
			  }
  
			  .no-stack .col.num10 {
				  width: 83.3% !important;
			  }
  
			  .video-block {
				  max-width: none !important;
			  }
  
			  .mobile_hide {
				  min-height: 0px;
				  max-height: 0px;
				  max-width: 0px;
				  display: none;
				  overflow: hidden;
				  font-size: 0px;
			  }
  
			  .desktop_hide {
				  display: block !important;
				  max-height: none !important;
			  }
		  }
	  </style>
  </head>
  
  <body class="clean-body" style="margin: 0; padding: 0; -webkit-text-size-adjust: 100%; background-color: #FFFFFF;">
	  <!--[if IE]><div class="ie-browser"><![endif]-->
	  <table class="nl-container" style="table-layout: fixed; vertical-align: top; min-width: 320px; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #FFFFFF; width: 100%;" cellpadding="0" cellspacing="0" role="presentation" width="100%" bgcolor="#FFFFFF" valign="top">
		  <tbody>
			  <tr style="vertical-align: top;" valign="top">
				  <td style="word-break: break-word; vertical-align: top;" valign="top">
					  <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td align="center" style="background-color:#FFFFFF"><![endif]-->
					  <div style="background-image:url('https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/defaultrows/background1.png');background-position:top center;background-repeat:repeat;">
						  <div class="block-grid " style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							  <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								  <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-image:url('https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/defaultrows/background1.png');background-position:top center;background-repeat:repeat;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								  <!--[if (mso)|(IE)]><td align="center" width="500" style="background-color:transparent;width:500px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
								  <div class="col num12" style="min-width: 320px; max-width: 500px; display: table-cell; vertical-align: top; width: 500px;">
									  <div class="col_cont" style="width:100% !important;">
										  <!--[if (!mso)&(!IE)]><!-->
										  <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
											  <!--<![endif]-->
											  <table cellpadding="0" cellspacing="0" role="presentation" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;" valign="top">
												  <tr style="vertical-align: top;" valign="top">
													  <td style="word-break: break-word; vertical-align: top; padding-bottom: 0px; padding-left: 0px; padding-right: 0px; padding-top: 0px; text-align: center; width: 100%;" width="100%" align="center" valign="top">
														  <h1 style="color:#e01253;direction:ltr;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;font-size:23px;font-weight:normal;line-height:120%;text-align:center;margin-top:0;margin-bottom:0;"><strong>VOOST</strong></h1>
													  </td>
												  </tr>
											  </table>
											  <div class="img-container center fixedwidth" align="center" style="padding-right: 0px;padding-left: 0px;">
												  <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr style="line-height:0px"><td style="padding-right: 0px;padding-left: 0px;" align="center"><![endif]--><img class="center fixedwidth" align="center" border="0" src="${getLogoURL()}" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; width: 100%; max-width: 125px; display: block;" width="125">
												  <!--[if mso]></td></tr></table><![endif]-->
											  </div>
											  <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
											  <div style="color:#555555;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
												  <div class="txtTinyMce-wrapper" style="line-height: 1.2; font-size: 12px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; color: #555555; mso-line-height-alt: 14px;">
													  <p style="font-size: 38px; line-height: 1.2; text-align: center; word-break: break-word; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 46px; margin: 0;"><strong>Welcome to the VOOST&nbsp;Platform</strong></p>
												  </div>
											  </div>
											  <!--[if mso]></td></tr></table><![endif]-->
											  <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
											  <div style="color:#555555;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
												  <div class="txtTinyMce-wrapper" style="line-height: 1.2; font-size: 12px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; color: #555555; mso-line-height-alt: 14px;">
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; font-size: 18px; mso-line-height-alt: 22px; margin: 0;"><span style="font-size: 18px; color: #000000;">Hi there&nbsp;<strong>${user.name}</strong></span></p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; mso-line-height-alt: 14px; margin: 0;">&nbsp;</p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; font-size: 18px; mso-line-height-alt: 22px; margin: 0;"><span style="font-size: 18px; color: #000000;">Thank you for registering as an applicant with Voost Jobs.</span></p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; mso-line-height-alt: 14px; margin: 0;">&nbsp;</p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; font-size: 18px; mso-line-height-alt: 22px; margin: 0;"><span style="font-size: 18px; color: #000000;">Your journey to finding your dream job starts right here!</span></p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; mso-line-height-alt: 14px; margin: 0;">&nbsp;</p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; font-size: 18px; mso-line-height-alt: 22px; margin: 0;"><span style="font-size: 18px; color: #000000;">If you haven’t already uploaded your CV, simply click <a href="${getAccountUrl()}" target="_blank" rel="noopener" style="text-decoration: underline;">here</a> to do so. Head into your ‘Account’ in the top right-hand corner on the Voost site, and click ‘Edit’ in your Account Settings and from there you can upload your CV.</span></p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; mso-line-height-alt: 14px; margin: 0;">&nbsp;</p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; font-size: 18px; mso-line-height-alt: 22px; margin: 0;"><span style="font-size: 18px; color: #000000;">From your Account tab, you are also able to track your application status and view your scheduled meetings with Voost’s video calling service.</span></p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; mso-line-height-alt: 14px; margin: 0;">&nbsp;</p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; font-size: 18px; mso-line-height-alt: 22px; margin: 0;"><span style="font-size: 18px; color: #000000;">To start searching for your future, click <a href="${getJobsURL()}" target="_blank" rel="noopener" style="text-decoration: underline;">here</a>!&nbsp;</span></p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; mso-line-height-alt: 14px; margin: 0;">&nbsp;</p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; mso-line-height-alt: 14px; margin: 0;">&nbsp;</p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; font-size: 18px; mso-line-height-alt: 22px; margin: 0;"><span style="font-size: 18px; color: #000000;">Good luck and happy Voosting!</span></p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; mso-line-height-alt: 14px; margin: 0;">&nbsp;</p>
													  <p style="line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; word-break: break-word; font-size: 18px; mso-line-height-alt: 22px; margin: 0;"><span style="font-size: 18px; color: #000000;">From the Voost team.</span></p>
												  </div>
											  </div>
											  <!--[if mso]></td></tr></table><![endif]-->
											  <div class="button-container" align="center" style="padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
												  <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="border-spacing: 0; border-collapse: collapse; mso-table-lspace:0pt; mso-table-rspace:0pt;"><tr><td style="padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px" align="center"><v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="${getAccountUrl()}" style="height:31.5pt; width:147.75pt; v-text-anchor:middle;" arcsize="10%" stroke="false" fillcolor="#3AAEE0"><w:anchorlock/><v:textbox inset="0,0,0,0"><center style="color:#ffffff; font-family:Arial, sans-serif; font-size:16px"><![endif]--><a href="${getAccountUrl()}" target="_blank" style="-webkit-text-size-adjust: none; text-decoration: none; display: inline-block; color: #ffffff; background-color: #3AAEE0; border-radius: 4px; -webkit-border-radius: 4px; -moz-border-radius: 4px; width: auto; width: auto; border-top: 1px solid #3AAEE0; border-right: 1px solid #3AAEE0; border-bottom: 1px solid #3AAEE0; border-left: 1px solid #3AAEE0; padding-top: 5px; padding-bottom: 5px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; text-align: center; mso-border-alt: none; word-break: keep-all;"><span style="padding-left:20px;padding-right:20px;font-size:16px;display:inline-block;letter-spacing:undefined;"><span style="font-size: 16px; margin: 0; line-height: 2; word-break: break-word; mso-line-height-alt: 32px;">VOOST Account</span></span></a>
												  <!--[if mso]></center></v:textbox></v:roundrect></td></tr></table><![endif]-->
											  </div>
											  <table class="divider" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" role="presentation" valign="top">
												  <tbody>
													  <tr style="vertical-align: top;" valign="top">
														  <td class="divider_inner" style="word-break: break-word; vertical-align: top; min-width: 100%; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; padding-top: 10px; padding-right: 10px; padding-bottom: 10px; padding-left: 10px;" valign="top">
															  <table class="divider_content" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-top: 0px solid transparent; height: 45px; width: 100%;" align="center" role="presentation" height="45" valign="top">
																  <tbody>
																	  <tr style="vertical-align: top;" valign="top">
																		  <td style="word-break: break-word; vertical-align: top; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;" height="45" valign="top"><span></span></td>
																	  </tr>
																  </tbody>
															  </table>
														  </td>
													  </tr>
												  </tbody>
											  </table>
											  <!--[if (!mso)&(!IE)]><!-->
										  </div>
										  <!--<![endif]-->
									  </div>
								  </div>
								  <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								  <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							  </div>
						  </div>
					  </div>
					  <div style="background-color:#555555;">
						  <div class="block-grid " style="min-width: 320px; max-width: 500px; overflow-wrap: break-word; word-wrap: break-word; word-break: break-word; Margin: 0 auto; background-color: transparent;">
							  <div style="border-collapse: collapse;display: table;width: 100%;background-color:transparent;">
								  <!--[if (mso)|(IE)]><table width="100%" cellpadding="0" cellspacing="0" border="0" style="background-color:#555555;"><tr><td align="center"><table cellpadding="0" cellspacing="0" border="0" style="width:500px"><tr class="layout-full-width" style="background-color:transparent"><![endif]-->
								  <!--[if (mso)|(IE)]><td align="center" width="500" style="background-color:transparent;width:500px; border-top: 0px solid transparent; border-left: 0px solid transparent; border-bottom: 0px solid transparent; border-right: 0px solid transparent;" valign="top"><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 0px; padding-left: 0px; padding-top:5px; padding-bottom:5px;"><![endif]-->
								  <div class="col num12" style="min-width: 320px; max-width: 500px; display: table-cell; vertical-align: top; width: 500px;">
									  <div class="col_cont" style="width:100% !important;">
										  <!--[if (!mso)&(!IE)]><!-->
										  <div style="border-top:0px solid transparent; border-left:0px solid transparent; border-bottom:0px solid transparent; border-right:0px solid transparent; padding-top:5px; padding-bottom:5px; padding-right: 0px; padding-left: 0px;">
											  <!--<![endif]-->
											  <div style="font-size:16px;text-align:center;font-family:Arial, Helvetica Neue, Helvetica, sans-serif">
												  <div height="10">&nbsp;</div>
											  </div>
											  <div style="font-size:16px;text-align:center;font-family:Arial, Helvetica Neue, Helvetica, sans-serif">
												  <div style="margin-top: 40px;border-top:1px solid #787878;margin-bottom: 40px;"></div>
											  </div>
											  <table class="social_icons" cellpadding="0" cellspacing="0" width="100%" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt;" valign="top">
												  <tbody>
													  <tr style="vertical-align: top;" valign="top">
														  <td style="word-break: break-word; vertical-align: top; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px;" valign="top">
															  <table class="social_table" align="center" cellpadding="0" cellspacing="0" role="presentation" style="table-layout: fixed; vertical-align: top; border-spacing: 0; border-collapse: collapse; mso-table-tspace: 0; mso-table-rspace: 0; mso-table-bspace: 0; mso-table-lspace: 0;" valign="top">
																  <tbody>
																	  <tr style="vertical-align: top; display: inline-block; text-align: center;" align="center" valign="top">
																		  <td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 7.5px; padding-left: 7.5px;" valign="top"><a href="${getFacebook()}" target="_blank"><img width="32" height="32" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-outline-circle-white/facebook@2x.png" alt="Facebook" title="Facebook" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
																		  <td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 7.5px; padding-left: 7.5px;" valign="top"><a href="${getTwitter()}" target="_blank"><img width="32" height="32" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-outline-circle-white/twitter@2x.png" alt="Twitter" title="Twitter" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
																		  <td style="word-break: break-word; vertical-align: top; padding-bottom: 0; padding-right: 7.5px; padding-left: 7.5px;" valign="top"><a href="${getLinkedIn()}" target="_blank"><img width="32" height="32" src="https://d2fi4ri5dhpqd1.cloudfront.net/public/resources/social-networks-icon-sets/t-outline-circle-white/linkedin@2x.png" alt="LinkedIn" title="LinkedIn" style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"></a></td>
																	  </tr>
																  </tbody>
															  </table>
														  </td>
													  </tr>
												  </tbody>
											  </table>
											  <!--[if mso]><table width="100%" cellpadding="0" cellspacing="0" border="0"><tr><td style="padding-right: 10px; padding-left: 10px; padding-top: 10px; padding-bottom: 10px; font-family: Arial, sans-serif"><![endif]-->
											  <div style="color:#C0C0C0;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;line-height:1.2;padding-top:10px;padding-right:10px;padding-bottom:10px;padding-left:10px;">
												  <div class="txtTinyMce-wrapper" style="color: #C0C0C0; font-size: 12px; line-height: 1.2; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; mso-line-height-alt: 14px;">
													  <p style="text-align: center; line-height: 1.2; word-break: break-word; font-size: 15px; mso-line-height-alt: 18px; margin: 0;"><span style="font-size: 15px;">Copyright © ${getYear()}, All rights reserved.</span></p>
													  <p style="text-align: center; line-height: 1.2; word-break: break-word; font-size: 15px; mso-line-height-alt: 18px; margin: 0;"><span style="font-size: 15px;"><a href="http://voostjobs.com/" style="color: #FFFFFF;">Voostjobs.com</a> is a trading of My Job App Ltd.</span></p>
													  <p style="text-align: center; line-height: 1.2; word-break: break-word; font-size: 15px; mso-line-height-alt: 18px; margin: 0;"><span style="font-size: 15px;">Registered number 10866837</span></p>
													  <p style="text-align: center; line-height: 1.2; word-break: break-word; mso-line-height-alt: 14px; margin: 0;">&nbsp;</p>
													  <p style="text-align: center; line-height: 1.2; word-break: break-word; font-size: 15px; mso-line-height-alt: 18px; margin: 0;"><span style="font-size: 15px;">Where to find us:</span></p>
													  <p style="text-align: center; line-height: 1.2; word-break: break-word; font-size: 15px; mso-line-height-alt: 18px; margin: 0;"><span style="font-size: 15px;"><a href="mailto:info@voostjobs.com" style="color: #FFFFFF;">info@voostjobs.com</a></span></p>
													  <p style="text-align: center; line-height: 1.2; word-break: break-word; mso-line-height-alt: 14px; margin: 0;">&nbsp;</p>
												  </div>
											  </div>
											  <!--[if mso]></td></tr></table><![endif]-->
											  <div style="font-size:16px;text-align:center;font-family:Arial, Helvetica Neue, Helvetica, sans-serif">
												  <div height="40">&nbsp;</div>
											  </div>
											  <!--[if (!mso)&(!IE)]><!-->
										  </div>
										  <!--<![endif]-->
									  </div>
								  </div>
								  <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
								  <!--[if (mso)|(IE)]></td></tr></table></td></tr></table><![endif]-->
							  </div>
						  </div>
					  </div>
					  <!--[if (mso)|(IE)]></td></tr></table><![endif]-->
				  </td>
			  </tr>
		  </tbody>
	  </table>
	  <!--[if (IE)]></div><![endif]-->
  </body>
  
  </html>
`;
};
