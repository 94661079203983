import React from 'react'
import Questionnaire from '../containers/questionnaire';

function RecruiterQuestions() {
    return (
        <React.Fragment>
            <Questionnaire />
        </React.Fragment>
    )
}

export default RecruiterQuestions;